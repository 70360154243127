import { render, staticRenderFns } from "./GeneralTemplate.vue?vue&type=template&id=f82b92cc&scoped=true&"
import script from "./GeneralTemplate.vue?vue&type=script&lang=js&"
export * from "./GeneralTemplate.vue?vue&type=script&lang=js&"
import style0 from "./GeneralTemplate.vue?vue&type=style&index=0&id=f82b92cc&prod&lang=scss&scoped=true&"
import style1 from "./GeneralTemplate.vue?vue&type=style&index=1&id=f82b92cc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f82b92cc",
  null
  
)

export default component.exports